import React from "react";
import styles from "./page-who-we-are.module.css";
import logo from "../images/squire-logo.svg";

import Section from "./section";
import Team from "./team";

const PageWhoWeAre = ({ teamMembers }) => {
  return (
    <Section bg="lightGeo">
      <div className={styles.inner}>
        <img src={logo} alt="Squire logo" className={styles.logo} />
        <h1 className={styles.title}>Who we are</h1>
        <p className={styles.para}>
          Squire founders have proven and award-winning track records in television, advertising and
          music content with expertise in directing, producing, marketing and finance. Our
          award-winning directors and creatives have been chosen for their ability to produce work
          that stands out, engages, inspires and innovates. At Squire we believe ideas should be
          owned. This is why we have a department whose sole aim is to develop and exploit our ideas
          around the world.
        </p>
      </div>
      <Team teamMembers={teamMembers} />
    </Section>
  );
};

export default PageWhoWeAre;
