import React from "react";
import styles from "./team.module.css";

const Team = ({ teamMembers }) => {
  return (
    <section className={styles.container}>
      <div className={styles.grid}>
        {teamMembers.nodes.map((member) => (
          <div className={styles.person} key={member.title}>
            <img
              className={styles.avatar}
              src={member.image.asset.url}
              alt={`${member.title}’s avatar`}
            />
            <div className={styles.title}>{member.title}</div>
            <div className={styles.bio}>{member.bio}</div>
          </div>
        ))}
      </div>
    </section>
  );
};

export default Team;
