import React from "react";
import { graphql } from "gatsby";
import {
  mapEdgesToNodes,
  filterOutDocsWithoutSlugs,
  filterOutDocsWithoutImages,
} from "../lib/helpers";

import GraphQLErrorList from "../components/graphql-error-list";
import Layout from "../containers/layout";
import SEO from "../components/seo";

// Sections
import SectionDirectors from "../components/section-directors";
import PageWhoWeAre from "../components/page-who-we-are";

export const query = graphql`
  query AboutPageQuery {
    site: sanitySiteSettings(_id: { regex: "/(drafts.|)siteSettings/" }) {
      title
      description
      keywords
    }
    team: allSanityTeamMember(sort: {order: ASC, fields: publishedAt}) {
      nodes {
        title
        bio
        image {
          asset {
            url
          }
        }
      }
    }
  }
`;

const AboutPage = (props) => {
  const { data, errors } = props;

  if (errors) {
    return (
      <Layout>
        <GraphQLErrorList errors={errors} />
      </Layout>
    );
  }

  const site = (data || {}).site;
  const teamMembers = (data || {}).team;

  return (
    <Layout>
      <SEO title={site.title} description={site.description} keywords={site.keywords} />
      <PageWhoWeAre teamMembers={teamMembers} />
      <SectionDirectors />
    </Layout>
  );
};

export default AboutPage;
